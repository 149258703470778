/* eslint-disable @typescript-eslint/ban-ts-comment */

import { ComponentType } from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import Cookies from 'js-cookie';

import { ReverseGeocodingResProps } from 'services/external/external.types';
import { GetSuggestedAddressesReqProps, SuggestionItem } from 'services/lookup/lookup.types';
import { ActionType } from 'services/wm/wm.types';

import { AppointmentItem } from 'store/appointments/appointments.types';
import { PlanOverrideProps, ValidCouponCodes } from 'store/signup/signup.types';

import { notifyError } from 'shared/Toast/Toast';

import { DEFAULT_SCALE_ADDON_CODE } from 'constants/defaults';
import { LINK_TO_AT_HOME_LABS } from 'constants/externalLinks';
import {
  SEMAGLUTIDE_PRICE_POINT,
  TRIPLE_THERAPY_PRICE_POINTS,
  WM_INSURANCE_VALID_PRICE_POINTS
} from 'constants/pricepoints';

import { AppointmentTypeItem } from 'models/appointment.types';
import { Option } from 'models/forms.types';
import { MembershipPlan, PricePoint } from 'models/plans.types';
import { AdminPrescriptionItem, CombinedPrescriptionItem } from 'models/prescriptions.types';
import { IncompleteSurveysProps, MailingAddress } from 'models/user.types';

import {
  NEW_MEMBERSHIP_PLANS,
  PATIENTS_AGE_LIMITS,
  SIGNUP_COUPON_CODES,
  UPGRADE_TO_ANNUAL_SHOWN_COOKIE
} from './constants';
import { DateFormat, FlowTypes, PathName, PlanCodes, PlanTypes, SignUpSteps } from './enums';
import { APPOINTMENT_TYPE_ID_REGEX } from './regExp';

import GenericAvatar from 'assets/images/avatars/profile-generic.png';

dayjs.extend(calendar);

export const validateBirth = (val: string, message: string, ageValidation = false) => {
  const date = dayjs(val, DateFormat.MM_DD_YYYY);
  if (!date.isValid()) return 'Please use the correct format: MM/DD/YYYY';

  const age = dayjs().diff(date, 'year');
  const isValidAge = ageValidation
    ? age >= PATIENTS_AGE_LIMITS.MIN && age <= PATIENTS_AGE_LIMITS.MAX
    : true;

  return (isValidAge && date.format(DateFormat.MM_DD_YYYY) === val) || message;
};

export const isSupportedAge = (dob: string) => {
  const age = dayjs().diff(dayjs(dob).format(DateFormat.YYYY_MM_DD), 'year');
  if (Number.isNaN(age)) {
    throw Error('invalid dob');
  }
  return age >= PATIENTS_AGE_LIMITS.MIN && age <= PATIENTS_AGE_LIMITS.MAX;
};

export const parseUpcomingAppointment = (item: AppointmentItem) => {
  const MINUTES_TO_MEETING = -15;
  const diff = dayjs().diff(item.appointmentTime?.startTime, 'm');
  if (
    diff >= MINUTES_TO_MEETING &&
    item.appointmentStatus === 'pending' &&
    item.appointmentMethod !== 'message'
  ) {
    return { ...item, isStartsSoon: true };
  }
  return item;
};

export const parseDayPart = () => {
  const hours = dayjs().hour();
  if (hours < 6) {
    return 'night';
  }
  if (hours < 10) {
    return 'morning';
  }
  if (hours < 18) {
    return 'afternoon';
  }
  return 'evening';
};

// Don't remove this function, because seems like we'll back to different avatars for different Genders later
export const parseAvatar = () => GenericAvatar;

export const parseDateForTodayAndTomorrow = (date: string | Date) => {
  return dayjs(date).calendar(null, {
    sameDay: '[today]',
    nextDay: '[tomorrow]',
    nextWeek: DateFormat.MM_DD,
    lastDay: DateFormat.MM_DD,
    lastWeek: DateFormat.MM_DD,
    sameElse: DateFormat.MM_DD
  });
};

export const parseTime = (time: string) => {
  return dayjs(time).calendar(null, {
    lastDay: '[yesterday •] h:mm A z',
    lastWeek: '[last] dddd [•] h:mm A z',
    nextDay: '[Tomorrow •] h:mm A z',
    nextWeek: 'dddd [•] h:mm A z',
    sameDay: '[Today •] h:mm A z',
    sameElse: `MM/DD/YYYY [•] h:mm A z`
  });
};

export const getStateFromGeocoding = (data: ReverseGeocodingResProps) => {
  return data.results
    .map((result) => {
      if (result.types.includes('administrative_area_level_1')) {
        return result.address_components.filter((address) =>
          address.types.includes('administrative_area_level_1')
        )[0];
      }
    })
    .filter((item) => !!item)[0];
};

export const formatNumber = (number: string) => {
  if (number.length !== 10) {
    return number;
  }
  return `+1 ${number.slice(0, 3)} ${number.slice(3, 6)} ${number.slice(6)}`;
};

export const formatPaymentMethod = (cardNumber?: string, cardType?: string) => {
  if (!cardNumber && !cardType) {
    return 'none';
  }
  if (cardNumber) {
    switch (cardNumber[0]) {
      case '3':
        // 4 and 7 are numbers for the Amex card
        if (['4', '7'].includes(cardNumber[1])) {
          return 'american_express';
        }
        return 'none';
      case '4':
        return 'visa';
      case '5':
        return 'master_card';
      case '6':
        return 'discover';

      default:
        return 'none';
    }
  } else {
    switch (cardType?.toLowerCase()) {
      case 'visa':
        return 'visa';
      case 'discover':
        return 'discover';
      case 'master card':
      case 'master':
        return 'master_card';
      case 'american_express':
        return 'american_express';
      default:
        return 'none';
    }
  }
};

export const formatProviderDefinition = (isUnlimitedPlan: boolean) =>
  isUnlimitedPlan ? 'My provider' : 'Preferred provider';

export const checkIfPromoCodeIsValid = (
  code: ValidCouponCodes | '',
  selectedFlow: FlowTypes,
  selectedPlanCode: PlanCodes,
  selectedPaymentInterval: number,
  isPayedWithInsurance?: boolean | null
): boolean => {
  let isValid = true;
  if (!code || isPayedWithInsurance) {
    isValid = false;
    return isValid;
  }
  const PROMO_CODE = SIGNUP_COUPON_CODES[code];

  // 2 initial checks
  if (!PROMO_CODE) {
    isValid = false;
    return isValid;
  }
  if (typeof PROMO_CODE === 'string') {
    return isValid;
  }

  // check if coupon can be applied to the selected flow
  if (PROMO_CODE.flowsAllowed !== 'All' && !PROMO_CODE.flowsAllowed.includes(selectedFlow)) {
    isValid = false;
  }

  // check if coupon can be applied to the selected period of payment
  if (
    PROMO_CODE.periodsAllowed !== 'All' &&
    !PROMO_CODE.periodsAllowed.includes(selectedPaymentInterval)
  ) {
    isValid = false;
  }

  // check if coupon can be applied to the selected plan
  if (PROMO_CODE.plansAllowed !== 'All') {
    const allowedPlanCodes = PROMO_CODE.plansAllowed.map((p) => typeof p === 'object' && p.plan);
    if (!allowedPlanCodes.includes(selectedPlanCode)) {
      isValid = false;
    }
  }

  return isValid;
};

export const getSpecificCouponCode = (code: string, planCode: PlanCodes): string => {
  // check if code is an invalid string and does not have type ValidCouponeCodes
  if (!SIGNUP_COUPON_CODES.hasOwnProperty(code)) {
    return '';
  }

  const coupon = SIGNUP_COUPON_CODES[code as ValidCouponCodes];
  if (typeof coupon === 'string') {
    return code;
  }
  // next we need to check if we have more than 1 coupon code for the selected promocode
  // for now let's just hardcode specific

  if (!coupon.hasSubCodes) {
    return code;
  }
  if (coupon.plansAllowed === 'All') {
    return code;
  }
  const specificCode = coupon.plansAllowed.find((p) => p.plan === planCode);
  if (!specificCode) {
    return '';
  }
  return specificCode.code;
};

export const getDiscountToShow = (discountPercentage: string, discountValue: string) =>
  parseInt(discountPercentage) > 0 ? discountPercentage : `$${discountValue}`;

export const getPaymentAmount = (
  promoCode: ValidCouponCodes | '',
  pricePoint?: PricePoint,
  planCode?: PlanCodes,
  isInitialAppointment?: boolean
): {
  discountPercentage: string;
  discountValue: string;
  newValue: string;
  oldValue: string;
} => {
  if (!pricePoint || !planCode) {
    return {
      discountPercentage: '',
      discountValue: '',
      newValue: '',
      oldValue: ''
    };
  }

  let discountPercentage = '0';
  const totalValue = Number(
    [PlanCodes.FlexCare, PlanCodes.LifeMDMembership, PlanCodes.UrgentCare].includes(planCode)
      ? isInitialAppointment
        ? pricePoint.initialAppointmentCost
        : pricePoint.subsequentAppointmentCost
      : pricePoint.totalCost
  );
  let discountValue = 0;

  if (!promoCode) {
    return {
      discountPercentage: '0',
      discountValue: '0',
      newValue: String(totalValue),
      oldValue: String(totalValue)
    };
  }

  const PROMO_CODE = SIGNUP_COUPON_CODES[promoCode];

  if (!!PROMO_CODE && typeof PROMO_CODE !== 'string') {
    if (PROMO_CODE.discount.unit === 'percentage') {
      discountPercentage = PROMO_CODE.discount.value;
      discountValue = (totalValue * parseFloat(discountPercentage)) / 100;
    } else if (PROMO_CODE.discount.unit === 'absolute') {
      discountValue = Number(PROMO_CODE.discount.value);
    } else if (PROMO_CODE.discount.unit === 'months') {
      discountValue = Number(pricePoint.monthlyPrice) * Number(PROMO_CODE.discount.value);
      // 'advanced' is the unitfor discounts with higher complexity of the calculations.
      // In this case we have to pass some condition directly in value
    } else if (PROMO_CODE.discount.unit === 'advanced') {
      const valueToDiscount = Number(
        [PlanCodes.FlexCare, PlanCodes.LifeMDMembership, PlanCodes.UrgentCare].includes(planCode)
          ? isInitialAppointment
            ? pricePoint.initialAppointmentCost
            : pricePoint.subsequentAppointmentCost
          : pricePoint.monthlyPrice
      );
      if (PROMO_CODE.discount.value === '25%offOnAppointment') {
        discountValue = (valueToDiscount * parseFloat('25%')) / 100;
      } else if (PROMO_CODE.discount.value === '50%offOnAppointmentOrFirstMonth') {
        discountValue = (valueToDiscount * parseFloat('50%')) / 100;
      } else if (PROMO_CODE.discount.value === '100%offOnAppointmentOrFirstMonth') {
        discountValue = valueToDiscount;
      }
    }
  }

  return {
    discountPercentage,
    discountValue: discountValue.toFixed(Number.isInteger(discountValue) ? 0 : 2),
    newValue:
      discountValue > 0
        ? (totalValue - discountValue).toFixed(Number.isInteger(totalValue - discountValue) ? 0 : 2)
        : String(totalValue),
    oldValue: String(totalValue)
  };
};

export const getPaymentNumbers = (
  totalPrice?: string,
  discount?: string,
  creditBalance?: string,
  isLifeMDPlusFlow?: boolean
) => {
  const numTotalPrice = Number(totalPrice);
  if (numTotalPrice === 0) {
    // if price is 0, we should skip !numTotalPrice condition
  } else if (!numTotalPrice) {
    return {
      creditUsed: '',
      discountValue: '',
      finalPrice: ''
    };
  }
  const discountValue = isLifeMDPlusFlow
    ? parseFloat(discount || '0')
    : !discount
      ? 0
      : (numTotalPrice * parseFloat(discount)) / 100;
  const creditUsed = !creditBalance
    ? 0
    : Math.min(numTotalPrice - discountValue, parseFloat(creditBalance));
  const finalPrice = isLifeMDPlusFlow
    ? numTotalPrice
    : Math.max(numTotalPrice - discountValue - parseFloat(creditBalance || '0'), 0);
  return {
    creditUsed: (creditUsed || 0).toFixed(2),
    discountValue: (discountValue || 0).toFixed(2),
    // if price has decimal, show it. If no, hide .00
    finalPrice: finalPrice.toFixed(finalPrice % 1 !== 0 ? 2 : 0)
  };
};

export const buildRedirectLink = (plan: PlanTypes, flow: FlowTypes, stepName: SignUpSteps) =>
  `${PathName.SignUp}/${plan}/${flow}/${stepName}`;

export const overridePlans = (
  membershipPlans: MembershipPlan[],
  planOverrides: PlanOverrideProps[]
): MembershipPlan[] => {
  // Get plan overrides. For each plan in plan overrides, update respective plan
  const updatedPlans = [...membershipPlans].map((membershipPlan) => {
    const overrideData = planOverrides.find((pp) => pp.planCode === membershipPlan.planCode);
    if (!overrideData) {
      return membershipPlan;
    }
    const updatedPlan = { ...membershipPlan };
    updatedPlan.pricePoints = membershipPlan.pricePoints.map((pp) => {
      if (pp.planPricePointId !== overrideData.planPricePointId) {
        return { ...pp, isDefault: false };
      }
      const overrideTotalCost = overrideData.totalCost;
      const overridePaymentInterval = overrideData.paymentInterval;
      const overrideAppointmentCost = overrideData.appointmentCostInDollars;
      const planPriceId = overrideData.planPricePointId;
      const appointmentPriceId = overrideData.appointmentPricePointId;
      const monthlyPlanCost = overrideData.planMonthlyCostInDollars;
      const componentId = overrideData.componentId;
      return {
        ...pp,
        isDefault: true,
        ...(monthlyPlanCost && { monthlyPrice: monthlyPlanCost }),
        ...(overrideTotalCost && { totalCost: overrideTotalCost }),
        ...(overridePaymentInterval && { paymentInterval: overridePaymentInterval }),
        ...(overrideAppointmentCost && { initialAppointmentCost: overrideAppointmentCost }),
        ...(planPriceId && { planPriceId }),
        ...(appointmentPriceId && { initialAppointmentPricePointId: appointmentPriceId }),
        ...(componentId && { initialAppointmentComponentId: componentId })
      };
    });
    // if for some reason overridden pricepoint is not at membership plans collection at database, we need to make t least 1 default
    if (updatedPlan.pricePoints.length > 0 && !updatedPlan.pricePoints.some((pp) => pp.isDefault)) {
      updatedPlan.pricePoints[0].isDefault = true;
    }
    return updatedPlan;
  });

  return updatedPlans;
};

export const isUpgradeToAnnualAlreadyShown = (userId: string) => {
  if (userId === '') {
    return false;
  }
  const parsedCookieValue = JSON.parse(Cookies.get(UPGRADE_TO_ANNUAL_SHOWN_COOKIE) ?? '[]');
  return !Array.isArray(parsedCookieValue) || parsedCookieValue?.includes(userId);
};

export const setIsUpgradeToAnnualShownIndicator = (userId: string) => {
  if (isUpgradeToAnnualAlreadyShown(userId)) {
    return;
  }
  const parsedCookieValue = JSON.parse(Cookies.get(UPGRADE_TO_ANNUAL_SHOWN_COOKIE) ?? '[]') ?? [];
  Cookies.set(
    UPGRADE_TO_ANNUAL_SHOWN_COOKIE,
    JSON.stringify(
      Array.isArray(parsedCookieValue) ? [...new Set([...parsedCookieValue, userId])] : [userId]
    ),
    {
      expires: 365 * 10
    }
  );
};

export const getElementByText = (str: string, tag = '*') => {
  if (!str) {
    return;
  }
  return [...document.querySelectorAll(tag)].filter(
    (el) =>
      // @ts-ignore
      (el.text && el.text.includes(str)) ||
      // @ts-ignore
      (el.children.length === 0 && el.outerText && el.outerText.includes(str))
  )[0];
};

export const parsePhoneNumber = (phone: string | null) => {
  if (!phone) {
    return '';
  }
  return `+1 ${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, phone.length)}`;
};

export const onFormError = (errors: DeepMap<FieldValues, FieldError>) => {
  setTimeout(() => {
    const errorsValues = Object.values(errors);
    if (errorsValues.length > 0) {
      if (errorsValues[0].ref) {
        const htmlElement: Element | undefined = getElementByText(errorsValues[0].message);
        if (!htmlElement) {
          return;
        }
        // @ts-ignore
        document.scrollingElement.scrollTop = htmlElement.offsetTop - 160;
      } else {
        onFormError(errorsValues[0]);
      }
    }
  });
};

export const filterByStartsSoon = (appointments: AppointmentItem[]) => {
  return appointments.sort((a, b) => +(b?.isStartsSoon || 0) - +(a?.isStartsSoon || 0));
};

export const sumUnreadMessages = (unreadCounts?: number[]) => {
  if (!Array.isArray(unreadCounts) || unreadCounts.length === 0) return 0;
  const unreadMessageCounts = unreadCounts?.filter((c) => c > 0);
  return unreadMessageCounts.length > 0
    ? unreadMessageCounts.reduce((prev, curr) => prev + curr)
    : 0;
};

export const getDateValue = (date: string) => {
  if (date) {
    return `${
      dayjs(date.toString()).isToday()
        ? 'Today, '
        : dayjs(date.toString()).isTomorrow()
          ? 'Tomorrow, '
          : `${dayjs(date.toString()).format(DateFormat.dddd)}, `
    } ${dayjs(date.toString()).format(DateFormat.MMMM_D)}: `;
  }

  return '';
};

export const getDataForAppointmentTimer = (appointmentsList: AppointmentItem[]) => {
  const firstAppointment = appointmentsList?.find((val) => val.isStartsSoon);

  if (!firstAppointment) return;
  return {
    appointmentID: firstAppointment._id,
    displayWaitTime: firstAppointment.displayWaitTime,
    hasStaffJoined: firstAppointment.hasStaffJoined,
    startTime: firstAppointment.appointmentTime?.startTime
  };
};

export const parseFlowForTheBackend = (f: FlowTypes): FlowTypes | 'queue' => {
  if (f === FlowTypes.QueueFlow) {
    return 'queue';
  }
  return f;
};

export const getPhraseForMembershipPlan = (v: PlanCodes, appointmentPrice = '139') => {
  switch (v) {
    case PlanCodes.UnlimitedMembership:
    case PlanCodes.ConciergeMembership:
      return 'Includes unlimited free appointments';
    case PlanCodes.FlexCare:
      return `Appointments for $${appointmentPrice}`;
    case PlanCodes.LifeMDMembership:
      return 'Begins after 30 days';
    case PlanCodes.WeightManagementMembership:
      return '3 month plan';
    case PlanCodes.TotalCareMembership:
      return 'Includes 1 free appointment per month';
    default:
      return '';
  }
};

export const getPartnerPricePoint = (flow: FlowTypes) => {
  switch (flow) {
    case FlowTypes.HRTClubFlow:
      return 'totalcare-hrt';
    case FlowTypes.HealthWarehouseFlow:
      return 'limited-access-free';
    default:
      return undefined;
  }
};
export const isQueueFlow = (f: FlowTypes) =>
  [
    FlowTypes.QueueFlow,
    FlowTypes.QueueFlowWithNewPlans,
    FlowTypes.QueueFlowWithNewPlansAlt,
    FlowTypes.InsuranceFlow,
    FlowTypes.InsuranceFlowAlt
  ].includes(f);

export const getPricePoint = (pricePoints: PricePoint[], qty?: number, specificPPId?: string) =>
  pricePoints.find((pp) =>
    !!specificPPId
      ? pp.planPricePointId === specificPPId
      : typeof qty === 'number'
        ? pp.paymentInterval.qty === qty
        : pp.isDefault
  );

export const getOrderTotalPrice = (
  selectedPlan: MembershipPlan,
  selectedPricePoint: PricePoint,
  isInitial = true
) => {
  if ([PlanCodes.LifeMDMembership, PlanCodes.FlexCare].includes(selectedPlan.planCode)) {
    return isInitial
      ? selectedPricePoint.initialAppointmentCost
      : selectedPricePoint.subsequentAppointmentCost;
  }
  return selectedPricePoint.totalCost;
};

export const getNewMembershipPlans = (plans: MembershipPlan[]) =>
  plans.filter((mp) => NEW_MEMBERSHIP_PLANS.includes(mp.planCode));

export const handleRequestCatch = (
  e: MessageEvent,
  alternativeMessage = 'Something went wrong, please try again later'
) => {
  if ('status' in e && e.status === 500) {
    return;
  }
  notifyError(e.data?.message || alternativeMessage);
};

export const divideOnTwoParts = <T>(arr: T[]) => {
  const list = [...arr];
  const middleIndex = Math.ceil(list.length / 2);

  const firstHalf = list.splice(0, middleIndex);
  const secondHalf = list.splice(-middleIndex);
  return { firstHalf, secondHalf };
};

export const convertNumToWord = (v: number): number | string => {
  const values = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five'
  };
  return values[v as keyof typeof values] || v;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyRetry = function (componentImport: any): Promise<{ default: ComponentType<any> }> {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(sessionStorage.getItem('retry-lazy-refreshed') || 'false');
    // try to import the component
    componentImport()
      .then(
        (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          component: { default: ComponentType<any> } | PromiseLike<{ default: ComponentType<any> }>
        ) => {
          sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
          resolve(component);
        }
      )
      .catch((error: Error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const checkIfPrescriptionIsGLP = (p: CombinedPrescriptionItem) => {
  if ('orderUrl' in p) {
    return false;
  } else {
    return !!p.trackingInfo;
  }
};

export const generateQueryString = (q: Record<string, string | undefined>) => {
  let queryString = '';
  if (q) {
    const queryKeys = Object.keys(q);
    queryKeys.forEach((key) => {
      if (q[key] && q[key]?.toString().length) {
        queryString += `${key}=${q[key]}&`;
      }
    });
    if (queryKeys.length > 0 && queryString[queryString.length - 1] === '&') {
      queryString = queryString.slice(0, -1);
    }
  }
  return queryString;
};

export const buildAddress = (suggestion: SuggestionItem, states: Option[]): MailingAddress => {
  const shallowCopy = { ...suggestion };

  let whiteSpace = ' ';
  // if (shallowCopy.secondary) {
  //   if (shallowCopy.entries > 1) {
  //     shallowCopy.secondary += ' (' + shallowCopy.entries + ' entries)';
  //   }
  //   whiteSpace = ' ';
  // }
  const address = shallowCopy.streetLine + whiteSpace + shallowCopy.secondary;

  return {
    address,
    city: shallowCopy.city,
    state: states.find((s) => s.value === shallowCopy.state)?.label || suggestion.state,
    zipCode: shallowCopy.zipcode
  };
};

export const buildQueryForAddress = (
  fullAddress: MailingAddress,
  states: Option[]
): GetSuggestedAddressesReqProps => {
  const shortState = states.find((s) => s.label === fullAddress.state)?.value || '';
  const search = `${fullAddress.address}`;
  return {
    includeOnlyStates: [shortState],
    maxResults: 2,
    preferCities: [fullAddress.city],
    preferStates: [shortState],
    search
  };
};

export const compareSuggestedAddress = (
  currentAddress: MailingAddress,
  suggestedAddress: MailingAddress
): boolean => {
  return (
    currentAddress.address === suggestedAddress.address &&
    currentAddress.city === suggestedAddress.city &&
    currentAddress.state === suggestedAddress.state &&
    currentAddress.zipCode === suggestedAddress.zipCode
  );
};

export const checkWMNotCompletedOnboarding = (
  isWeightManagement: boolean,
  isFirstAppointmentCompleted: boolean
) => isWeightManagement && !isFirstAppointmentCompleted;

export const isAbleToScheduleNewAppointment = (
  appointmentsList: AppointmentItem[],
  isWeightManagement: boolean,
  isFirstAppointmentCompleted: boolean,
  isAsyncPlan?: boolean
) => {
  return (
    (!checkWMNotCompletedOnboarding(isWeightManagement, isFirstAppointmentCompleted) ||
      !appointmentsList.length) &&
    !isAsyncPlan
  );
};

export const distanceByCoords = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  // Convert latitude and longitude from degrees to radians
  lat1 = lat1 * (Math.PI / 180);
  lon1 = lon1 * (Math.PI / 180);
  lat2 = lat2 * (Math.PI / 180);
  lon2 = lon2 * (Math.PI / 180);

  // Haversine formula
  const dlat = lat2 - lat1;
  const dlon = lon2 - lon1;
  const a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Radius of the Earth in kilometers (mean value)
  const radiusKm = 6371.0;

  // Calculate the distance in kilometers
  const distanceKm = radiusKm * c;

  // Convert distance to miles
  return (distanceKm * 0.621371).toFixed(1);
};

export const isAdminPrescription = (
  object: CombinedPrescriptionItem
): object is AdminPrescriptionItem => 'orderUrl' in object;

export const findAppointmentType = (
  typeToFind: string,
  appointments: AppointmentTypeItem[] = []
): AppointmentTypeItem | undefined =>
  appointments.find(
    (item) => item.displayName.toLowerCase().trim() === typeToFind.trim().toLowerCase()
  );

export const getLatestSurvey = (
  surveys: IncompleteSurveysProps[] | undefined,
  type: ActionType
) => {
  if (!surveys?.length) {
    return null;
  }
  const filtered = surveys.filter((s) => s.type === type);
  if (filtered.length === 0) {
    return null;
  }
  return filtered.at(-1);
};

const checkIFGivenStringIsAppointmentTypeID = (id: string | null) => {
  if (!id) {
    return false;
  }
  const regex = new RegExp(APPOINTMENT_TYPE_ID_REGEX);
  return regex.test(id);
};

export const findAppointmentTypeByData = (
  types: AppointmentTypeItem[] | undefined,
  query: string | null | undefined
): AppointmentTypeItem | null => {
  if (!query || !types?.length) {
    return null;
  }
  return (
    types.find((type) => {
      if (checkIFGivenStringIsAppointmentTypeID(query)) {
        return type._id === query;
      }
      return type.displayName.trim().toLowerCase() === query.toLowerCase();
    }) ?? null
  );
};

export const findDisabledDates = (
  datesWithSlots: string[],
  periodStart: string,
  periodEnd: string
) => {
  const disabledDates = [];
  const start = dayjs(periodStart);
  const end = dayjs(periodEnd);
  for (let m = start; m.isSameOrBefore(end); m = m.add(1, 'day')) {
    if (!datesWithSlots.includes(m.format(DateFormat.YYYY_MM_DD))) {
      disabledDates.push(m.format(DateFormat.YYYY_MM_DD));
    }
  }
  return disabledDates;
};

export const checkIfUserIsTTUser = (ppID?: string) =>
  !!ppID && TRIPLE_THERAPY_PRICE_POINTS.includes(ppID);

export const checkIfUserIsInsuranceUser = (ppID?: string) =>
  !!ppID && WM_INSURANCE_VALID_PRICE_POINTS.includes(ppID);

export const openAshLink = () => window.open(LINK_TO_AT_HOME_LABS, '_blank');

export const calculateBMI = (data: {
  heightFt: number | string;
  heightIn: number | string;
  weight: number | string;
}): string => {
  return (
    (Number(data.weight) / (Number(data.heightFt) * 12 + Number(data.heightIn)) ** 2) *
    703
  ).toFixed(1);
};

export const getScalesAddonData = (pp?: PricePoint, includesScales?: boolean) => {
  if (!pp || !includesScales) {
    return null;
  }
  const addon = pp.addOns?.find((a) => a.code === DEFAULT_SCALE_ADDON_CODE);
  if (!addon) {
    return null;
  }
  return { code: addon.code, unitPrice: addon.unitPrice };
};

export const applyPromoCode = (
  pp: PricePoint,
  flow: FlowTypes | 'authorized'
): ValidCouponCodes | '' => {
  if (flow === FlowTypes.WeightManagementFlowInsuranceDiscovery) {
    switch (pp.paymentInterval.qty) {
      case 1:
        return '74OFF1MO';
      case 3:
        return 'GLP125REC';
      default:
        return '';
    }
  }
  if (flow === FlowTypes.WeightManagementBalladHealth) {
    switch (pp.planPricePointId) {
      case 'wm-one-month-149':
        return 'GLPBH59FLATREC';
      case 'wm-three-months-447':
        return 'GLPBH222FLATREC';
      default:
        return '';
    }
  }
  switch (pp.planPricePointId) {
    case 'wm-three-months-447':
      return 'GLP115REC';
    case 'wm-six-months-894':
      return 'GLP125REC';
    case 'wm-twelve-months-1788':
      return 'GLP150REC';
    case SEMAGLUTIDE_PRICE_POINT:
      return 'GLP100FLATREC';
    default:
      return '';
  }
};

export const buildSearch = (prefix = '', originalSearch = '') =>
  `${!!prefix ? prefix + '&' : ''}${originalSearch}`;

export const parseDecimal = (val: string | number): string => {
  const numericValue = Number(val);
  return String(numericValue.toFixed(numericValue % 1 === 0 ? 0 : 2));
};

export const findMaintenancePP = (pps: PricePoint[] | undefined, { qty = 1, keyword = '' }) => {
  if (!pps?.length) {
    return null;
  }
  return pps.find(
    (pp) =>
      pp.isWeightMaintenance &&
      pp.paymentInterval.qty === qty &&
      pp.planPricePointId.includes(keyword)
  );
};

export const calculateDiscountPercentageByPrices = (price: number, oldPrice: number) => {
  return Math.ceil(((oldPrice - price) / oldPrice) * 100);
};

export const cutURLSlugs = (url: string | null) => {
  if (!url) {
    return '';
  }
  const parsedUrl = new URL(url);
  return parsedUrl.origin;
};
